<template>
  <div class="gallery-hldr clearfix">
    <div id="gallery" class="content">
      <agile class="main-slider slideshow-container" ref="main" :options="options" @after-change="afterChange">
        <div
          class="slide"
          v-for="(slide, index) in slideList"
          :key="index"
          :class="`slide--${index}`">
          <img v-bind="mainProps" :src="slide" :alt="hotelName || 'hotel-image'" @error="showDefaultImage" :class="sliderLength===1 && 'one-image'" width="1045" height="450">
        </div>

        <template slot="prevButton">
          <i class="fas fa-chevron-right"></i>
        </template>
        <template slot="nextButton">
          <i class="fas fa-chevron-left"></i>
        </template>
      </agile>

      <div class="thumbnails" v-if="(slideList.length>1)">
        <div
          class="slide--thumbniail" :class="{'slide--thumbniail-active': index === currentIndex}"
          v-for="(slide, index) in slideList?.map((src) => `${src}?speedsize=w_400`)"
          :key="index"
          @click="$refs.main.goTo(index)">
          <img v-bind="mainProps" :src="slide" :alt="hotelName || 'hotel-slide-thumb'" @error="showDefaultImage" width="99" height="90">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    hotelName: {
      type: String,
      default: '',
    },
    destinationImage: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    sliderLength() {
      return this.slides.length;
    },
  },
  data() {
    return {
      options: {
        dots: false,
        fade: true,
        navButtons: true,
        rtl: true,
        speed: 500,
        slidesToshow: 8,
      },
      slideList: [],
      currentIndex: 0,
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
      },
    };
  },
  beforeMount() {
    const s = [
      `${this.imageDomain}/assets/img/search/hotel2.jpg`,
      `${this.imageDomain}/assets/img/search/hotel3.jpg`,
      `${this.imageDomain}/assets/img/search/hotel4.jpg`,
      `${this.imageDomain}/assets/img/search/hotel5.jpg`,
      `${this.imageDomain}/assets/img/search/room-1.jpg`,
    ];
    this.slideList = (this.slides.length === 0) ? s : this.slides;
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.main) {
        this.$refs.main.reload();
      }
    }, 1500);
  },
  methods: {
    afterChange({ currentSlide }) {
      this.currentIndex = currentSlide;
    },
    showDefaultImage(ev) {
      ev.target.src = this.destinationImage;
    },
  },
};
</script>
<style>
 #gallery .slide img.one-image {
   object-fit: fill;
 }
</style>
<style scoped>
  @media (max-width: 479px){
    .holiday-hotel-details, .facilitie-list{
      display: none;
    }
  }
</style>

<style lang="sass">
.main-slider
  margin-bottom: 30px

.thumbnails
  // width: calc(100% + 10px)
  clear: both
  margin: 0
  padding: 0
  white-space: nowrap
  overflow-x: auto

// Basic VueAgile styles
.gallery-hldr .agile
  &__nav-button
    background: transparent
    top: calc(50% - 20px)
    border: none
    color: #fff
    cursor: pointer
    font-size: 40px
    transition-duration: .3s

    &--prev
      right: 0
      left: auto
    &--next
      left: 0
      right: auto

    .thumbnails &
      position: absolute
      top: 50%
      transform: translateY(-50%)

      &--prev
        left: -45px

      &--next
        right: -45px

    &:hover
      color: #ccc
      box-shadow: none

  &__dot
    margin: 0 10px

    button
      background-color: #eee
      border: none
      border-radius: 50%
      cursor: pointer
      display: block
      height: 10px
      font-size: 0
      line-height: 0
      margin: 0
      padding: 0
      transition-duration: .3s
      width: 10px

    &--current,
    &:hover
      button
        background-color: #888

// Slides styles
#gallery .slide
  align-items: center
  box-sizing: border-box
  color: #fff
  display: flex
  height: 560px
  justify-content: center

  &--thumbniail
    cursor: pointer
    transition: all .3s
    margin: 5px 2px
    padding: 2px
    display: inline-flex
    border: 1px solid #ccc
    opacity: .5

    &:hover
      box-shadow: gray 1px 1px 10px

    img
      height: 90px
      max-width: 99px

    &-active
      opacity: 1

  img
    height: 100%
    object-fit: cover
    object-position: center
    width: 100%

@media (max-width: 479px)
  .main
    margin-bottom: 0

  .thumbnails
    height: 80px

    .slide--thumbniail
      width: 18.5%
      height: auto
      margin: 1px

      &-active
        border: 2px solid black
        padding: 1px

      img
        max-width: 100%
        width: 100%
        height: 30px
  #gallery .slide
    height: 340px
</style>
